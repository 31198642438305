// polyfills.js
import { Buffer } from 'buffer';
import process from 'process';
import { EventEmitter } from 'events';

// Setup global Buffer
if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
  window.global = window;
  window.process = process;
  window.EventEmitter = EventEmitter;
}

// Setup module exports for CommonJS compatibility
if (typeof window !== 'undefined' && !window.module) {
  window.module = {
    exports: {}
  };
}

// Export for ESM compatibility
export {
  Buffer,
  process,
  EventEmitter
};