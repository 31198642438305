import * as React from 'react';
const { useState, useEffect, useCallback } = React;


const loadingPhrases = {
  default: [
    'Flushing the system...',
    'Polishing the porcelain...',
    'Unclogging the code...',
    'Scrubbing the server...',
    'Disinfecting the database...',
  ],
  missions: [
    'Unrolling the mission scrolls...',
    'Plunging into new adventures...',
    'Wiping away the competition...',
    'Freshening up the quests...',
    'Restocking the TP dispenser...',
  ],
  telegramConnect: [
    'Sending carrier pigeons to Telegram...',
    'Flushing messages through the pipes...',
    "Toilet-papering Telegram's servers...",
    'Plumbing the depths of connectivity...',
    'Practicing our royal flush...',
  ],
  guest: [
    'Preparing a temporary throne...',
    'Setting up guest towels...',
    'Arranging complimentary toiletries...',
    'Freshening up the guest bathroom...',
    'Rolling out the toilet paper carpet...',
  ],
};

const loadingStates = {
  default: [
    'Initializing Firebase...',
    'Authenticating user...',
    'Loading game assets...',
    'Preparing game instance...',
    'Setting up game environment...',
  ],
  guest: [
    'Setting up guest account...',
    'Preparing limited access...',
    'Loading guest features...',
    'Setting up temporary game instance...',
    'Readying guest environment...',
  ],
};

const LOADING_TIMEOUT = 30000; // 30 seconds

const LoadingScreen = ({
  type = 'default',
  error = null,
  customMessage = '',
  onTimeout = () => {},
  isGuest = false,
}) => {
  const [phrase, setPhrase] = useState('');
  const [progress, setProgress] = useState(0);
  const [currentState, setCurrentState] = useState(0);

  const updatePhrase = useCallback(() => {
    const phrases = loadingPhrases[isGuest ? 'guest' : type] || loadingPhrases.default;
    setPhrase(phrases[Math.floor(Math.random() * phrases.length)]);
  }, [type, isGuest]);

  const updateLoadingState = useCallback(() => {
    const states = isGuest ? loadingStates.guest : loadingStates.default;
    setCurrentState((prevState) => (prevState < states.length - 1 ? prevState + 1 : prevState));
  }, [isGuest]);

  useEffect(() => {
    updatePhrase();
    const phraseInterval = setInterval(updatePhrase, 5000);
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + Math.random() * 3;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 200);
    const stateInterval = setInterval(updateLoadingState, 3000);

    const timeoutId = setTimeout(() => {
      onTimeout();
    }, LOADING_TIMEOUT);

    return () => {
      clearInterval(phraseInterval);
      clearInterval(progressInterval);
      clearInterval(stateInterval);
      clearTimeout(timeoutId);
    };
  }, [type, isGuest, customMessage, updatePhrase, updateLoadingState, onTimeout]);

  if (error) {
    return (
      <div className="loading-screen error">
        <div className="loading-content">
          <h2>Error</h2>
          <p>{error}</p>
          <p>Please refresh the page and try again.</p>
        </div>
      </div>
    );
  }

  const currentLoadingStates = isGuest ? loadingStates.guest : loadingStates.default;

  return (
    <div className="loading-screen" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="loading-content">
        <div className="spinner"></div>
        <h2>{progress >= 100 ? 'Almost there...' : 'Loading...'}</h2>
        <p className="loading-phrase">{phrase}</p>
        <p className="loading-state">
          {customMessage || (progress >= 100 ? 'Final touches...' : currentLoadingStates[currentState])}
        </p>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <p className="loading-footer">
          {isGuest
            ? 'Please wait while we prepare your temporary guest experience...'
            : 'Please wait while we prepare your royal throne...'}
        </p>
      </div>
    </div>
  );
};

export default LoadingScreen;
